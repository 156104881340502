import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const BlogRoll = (props) => {
    const { data } = props
    const allPosts = data.allMarkdownRemark.edges

    const [dataValue, setDataValue] = useState('')
    const [posts, setPosts] = useState([...allPosts])
    const emptyQuery = ''
    const [state, setState] = useState({
        filteredData: [],
        query: emptyQuery,
    })

    useEffect(() => {
        if (!window.history.state) {
            setDataValue('')
        } else {
            setDataValue(window.history.state.value)
        }
    })

    useEffect(() => {
        const query = dataValue
        const filteredData = allPosts.filter((post) => {
            const { topic } = post.node.frontmatter
            return topic && topic.toLowerCase().includes(query.toLowerCase())
        })
        setState({ query, filteredData })
    }, [dataValue, allPosts])

    useEffect(() => {
        const { filteredData, query } = state
        const hasSearchResults = filteredData && query !== emptyQuery
        setPosts(hasSearchResults ? filteredData : allPosts)
    }, [state, allPosts])

    const [list, setList] = useState([...posts.slice(0, 3)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(posts.length > 3)

    useEffect(() => {
        setList([...posts.slice(0, 3)])
    }, [posts])

    const loadRef = useRef()
    const handleObserver = (entities) => {
        const target = entities[0]
        if (target.isIntersecting) {
            setLoadMore(true)
        }
    }

    useEffect(() => {
        let options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        }
        const observer = new IntersectionObserver(handleObserver, options)
        if (loadRef.current) {
            observer.observe(loadRef.current)
        }
    }, [])

    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < posts.length
            const nextResults = isMore
                ? posts.slice(currentLength, currentLength + 3)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore, posts, list])

    useEffect(() => {
        const isMore = list.length < posts.length
        setHasMore(isMore)
    }, [list, posts])
    return (
        <div className="container is-max-widescreen brandonRegular">
            <section className="article">
                {list.map(({ node: post }) => (
                    <div className="column is-12 allPosts" key={post.id}>
                        <Link to={post.fields.slug}>
                            <article
                                className={`box ${
                                    post.frontmatter.featuredpost
                                        ? 'is-featured'
                                        : ''
                                }`}
                                style={{
                                    boxShadow:
                                        '1px 1px 5px 0px rgba(0,0,0,0.3)',
                                }}
                            >
                                <div
                                    className="title is-size-1 is-size-2-title-mobile BrandonGrotesqueRegular"
                                    style={{ marginBottom: '.5rem' }}
                                >
                                    {post.frontmatter.title}
                                </div>
                                <p className="is-size-6">
                                    {post.frontmatter.date}
                                </p>
                                <header>
                                    {post.frontmatter.featuredimage ? (
                                        <div className="featured-thumbnail">
                                            <PreviewCompatibleImage
                                                imageInfo={{
                                                    image: post.frontmatter
                                                        .featuredimage,
                                                    alt: `${post.frontmatter.title}`,
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    <div className="container BrandonGrotesqueRegular exceptIos">
                                        <p className="post-meta">
                                            <br />
                                            <span className="is-size-5 is-size-6-mobile is-block blockPrev">
                                                {post.excerpt}
                                            </span>
                                        </p>
                                    </div>
                                </header>
                            </article>
                        </Link>
                        <hr className="styled-separator" aria-hidden="true" />
                    </div>
                ))}
                <div className="has-text-centered" ref={loadRef}>
                    {hasMore ? <p>Loading...</p> : null}
                </div>
            </section>
        </div>
    )
}

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

export default () => (
    <StaticQuery
        query={graphql`
            query BlogRollQuery {
                allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            id
                            excerpt(pruneLength: 400)
                            fields {
                                slug
                            }
                            frontmatter {
                                date(formatString: "MMMM DD, YYYY")
                                topic
                                title
                                templateKey
                                featuredpost
                                featuredimage {
                                    childImageSharp {
                                        gatsbyImageData(
                                            quality: 100
                                            formats: WEBP
                                            layout: FULL_WIDTH
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data, count) => <BlogRoll data={data} count={count} />}
    />
)
