import React from 'react'

import BlogRoll from '../components/BlogRoll'
import Layout from '../components/Layout'
import { SEO } from '../components/SEO'

const BlogIndexPage = () => {
    return (
        <Layout>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <BlogRoll />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default BlogIndexPage

export const Head = () => <SEO />
